import bootstrapper from '../bootstrapper';
import {
  displayFlashErrorMessage, transactionUUID, installmentsNumber, disableItems,
} from './checkoutHelpers';
import { clientSideFailedPaymentPayload, createFailedPayment } from './createFailedPayment';
import sendTrackpointEvent from '../trackpoint/sendEvent';

let stripeElements;
let stripe;
let paymentIntentInfo;

const requestUrl = () => {
  const paymentForm = document.getElementById('payment-form');
  const serverUrl = paymentForm.action;

  return serverUrl;
};

const couponCode = () => {
  const couponField = document.querySelector('#shop_payment_installment_coupon');
  const coupon = couponField?.value;

  return coupon;
};

const createFailedPaymentWithError = async (error) => {
  const body = clientSideFailedPaymentPayload('stripe_intent', error, null, installmentsNumber());
  createFailedPayment(body).then(res => res.json())
    .then(res => displayFlashErrorMessage(res.error_message));
};

const payloadKey = () => (requestUrl().includes('enrollments') ? 'shop_payment_installment' : 'shop_payment');

const createPaymentIntentPayload = () => {
  const payload = {
    coupon: couponCode(),
    [payloadKey()]: {
      number_of_installments: installmentsNumber(),
      transaction_uuid: transactionUUID(),
    },
  };

  return payload;
};

const createPaymentIntent = async () => {
  const CSRFToken = document.querySelector("[name='csrf-token']")?.content;
  const url = requestUrl();
  const body = createPaymentIntentPayload();
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': CSRFToken,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options);
};

const processedResponse = async () => {
  const response = await createPaymentIntent();
  const responseData = await response.json();

  if (response.ok) return responseData;

  throw new Error(responseData.error);
};

const handleStripeElementChangeEvent = (event) => {
  const formEvent = new CustomEvent(
    'payment_form_change',
    { detail: { disable_payment: event.value.type === 'us_bank_account' && !event.complete } },
  );
  document.getElementById('payment-form').dispatchEvent(formEvent);

  if (event.value.type === 'us_bank_account') {
    let eventName;

    if (event.complete) {
      eventName = 'UsBankValidationLinked';
    } else if (event.empty) {
      eventName = 'UsBankMethodSelected';
    } else {
      eventName = 'UsBankMethodFormFilling';
    }

    sendTrackpointEvent({
      ...bootstrapper.stripeDataToLog,
      event: eventName,
      payment_method: event.value.type,
    });
  }
};

const buildPaymentElement = async () => {
  try {
    paymentIntentInfo = await processedResponse();
    stripeElements = stripe.elements({ clientSecret: paymentIntentInfo.client_secret });
    const paymentElement = stripeElements.create(
      'payment', { paymentMethodOrder: ['alipay', 'us_bank_account', 'card'] },
    );
    paymentElement.addEventListener('change', handleStripeElementChangeEvent);
    paymentElement.mount('#payment-element');
  } catch (error) {
    displayFlashErrorMessage(error.message);
    // TODO: SELL-1044
    // When an error is generated in the `/enrollments/:enrollment_id/payment_installments`
    disableItems();
  }
};

export const payStripeIntent = () => {
  stripe.confirmPayment({
    elements: stripeElements,
    confirmParams: {
      return_url: `${window.location.origin}${paymentIntentInfo.success_callback_path}`,
    },
  })
    .then((result) => {
      if (result.error) {
        createFailedPaymentWithError(result.error);
      }
    });
};

export const stripeGatewayLoadForm = () => {
  stripe = window.Stripe(
    bootstrapper.stripePublishableKey,
    { locale: bootstrapper.locale },
  );
  return buildPaymentElement();
};
