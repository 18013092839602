import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static values = {
    includeBlank: { type: Boolean, default: false }, custom: { type: Boolean, default: false },
  }

  static targets = ['state', 'country']

  connect () {
    this.fetchStates();
  }

  updateStates (states) {
    while (this.stateTarget.firstChild) {
      this.stateTarget.removeChild(this.stateTarget.firstChild);
    }

    const closestWrapper = this.stateTarget.closest('.js-input-wrapper');
    const targetToDisplay = this.customValue ? closestWrapper : this.stateTarget;

    if (!states.length) {
      this.stateTarget.removeAttribute('required');
      targetToDisplay.hidden = true;
    } else {
      this.stateTarget.setAttribute('required', '');
      targetToDisplay.hidden = false;
    }

    if (this.includeBlankValue) {
      this.stateTarget.removeAttribute('required');
      this.stateTarget.add(new Option());
    }

    const selectedState = this.stateTarget.getAttribute('value');
    states.forEach((state) => {
      this.stateTarget.add(new Option(state, state, false, selectedState === state));
    });
  }

  fetchStates () {
    if (!this.hasCountryTarget) return;

    const countryCode = this.countryTarget.selectedOptions[0].value;
    axios.get('/api/v1/standard_app_adress/residential_states', { params: { country: countryCode } }).then((response) => {
      this.updateStates(response.data.states);
    })
      .catch((err) => {
        console.error(err);
      });
  }
}
