// query Params are parsed in this way to avoid a large URLSearchParams polyfill
// (since IE doesn't support it) and optimize for performance
const queryParams = () => window.location.href.split('?')[1];
const newQueryParams = (params, couponValue) => {
  const queryParamsArray = [];
  const couponKeyValue = `coupon=${couponValue}`;
  let isCouponParamPresent = false;

  if (params === undefined) {
    return couponKeyValue;
  }
  params.split('&').forEach((keyValue) => {
    const paramKey = keyValue.split('=')[0];
    if (paramKey === 'coupon') {
      isCouponParamPresent = true;
      queryParamsArray.push(couponKeyValue);
    } else if (paramKey !== 'coupon_action') {
      queryParamsArray.push(keyValue);
    }
  });

  if (!isCouponParamPresent) {
    queryParamsArray.push(couponKeyValue);
  }

  return queryParamsArray.join('&');
};

const verifyCouponHandler = (event) => {
  const button = event.target;
  const input = document.querySelector('.js-coupon-code-field');
  if (input.value) {
    window.location.href = `${
      button.dataset.baseUrl
    }?${newQueryParams(queryParams(), input.value)}`;
  }
};

const verifyCouponElement = (verifyCouponBtn) => {
  if (verifyCouponBtn) verifyCouponBtn.addEventListener('click', verifyCouponHandler);
};

const verifyCouponDisconnect = (verifyCouponBtn) => {
  if (verifyCouponBtn) verifyCouponBtn.removeEventListener('click', verifyCouponHandler);
};

export default () => {
  const verifyCouponBtn = document.querySelector('.js-verify-coupon');
  verifyCouponElement(verifyCouponBtn);

  return () => {
    verifyCouponDisconnect(verifyCouponBtn);
  };
};
